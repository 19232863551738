.title {
  max-width: 553px;
  color: var(----ui-colors-grey-dark);
  margin-bottom: 24px;

  @media (max-width: token(breakpoints.maxSm)) {
    max-width: 375px;
  }
}

.extraWrap {
  position: relative;
}

.extra {
  position: absolute;
  top: -3px;
  right: -20px;
  color: #a4aab6;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;

  @media (max-width: token(breakpoints.maxSm)) {
    font-size: 30px;
    line-height: 40px;
    font-family: inherit;
  }
}
